import Subheader from '@/components/home/subheader/Subheader.vue'
import AsSeenOn from '@/components/home/AsSeenOn'
import BestWay from '@/components/home/BestWay'
import Comparing from '@/components/home/Comparing'
import SupportedCoins from '@/components/home/SupportedCoins'
import Features from '@/components/home/Features'
import Slider from '@/components/home/Slider'
import Join from '@/components/home/Join'
import CardRow from '@/components/card-row/CardRow.vue'



export default {
  name: 'Home',
  components: {
    Subheader,
    AsSeenOn,
    BestWay,
    Comparing,
    SupportedCoins,
    Features,
    Slider,
    Join,
		CardRow
  },
	metaInfo () {
		return {
			title: 'Crypto Payment Gateway Processor - Bitcoin and others on Blockchain | 1TN',
			meta: [{
				name: 'description',
				content: '1TN accepts a wide range of digital currencies for online payments with the lowest transaction fee',
			},
      {
				name: 'og:url',
				content: this.createCanonicalUrl(),
			},
      {
				name: 'og:type',
				content: 'website',
			},
      {
				name: 'og:title',
				content: '1tn',
			},
      {
				name: 'og:description',
				content: '1TN accepts a wide range of digital currencies for online payments with the lowest transaction fee',
			},
      {
				name: 'og:image',
				content: require('@/assets/images/charts-with-shadow.png'),
			},
      {
				name: 'twitter:card',
				content: 'summary_large_image',
			},
      {
				name: 'twitter:domain',
				content: '1tn.com',
			},
      {
				name: 'twitter:url',
				content: this.createCanonicalUrl(),
			},
      {
				name: 'twitter:title',
				content: '1tn',
			},
      {
				name: 'twitter:description',
				content: '1TN accepts a wide range of digital currencies for online payments with the lowest transaction fee',
			},
      {
				name: 'twitter:image',
				content: require('@/assets/images/charts-with-shadow.png'),
			}
    ],
			link: [{
				rel: 'canonical',
				href: this.createCanonicalUrl()
			}],
			titleTemplate: null
		}
	},
	computed: {
		receiveItems () {
      return [
        {
          order: 1,
          title: this.trans('home_receive_payments_item_1_title'),
          description: this.trans('home_receive_payments_item_1_subtitle'),
          src: require('@/assets/images/card-icons/circle_icon.svg')
        },
        {
          order: 2,
          title: this.trans('home_receive_payments_item_2_title'),
          description: this.trans('home_receive_payments_item_2_subtitle'),
          src: require('@/assets/images/card-icons/message_icon.svg')
        },
        {
          order: 3,
          title: this.trans('home_receive_payments_item_3_title'),
          description: this.trans('home_receive_payments_item_3_subtitle'),
          src: require('@/assets/images/card-icons/phone_icon.svg')
        },
        {
          order: 4,
          title: this.trans('home_receive_payments_item_4_title'),
          description: this.trans('home_receive_payments_item_4_subtitle'),
          src: require('@/assets/images/home-wallet-icon.svg')
        }
      ]
    },
		chooseItems () {
      return [
        {
          order: 1,
          title: this.trans('home_choose_us_item_1_title'),
          description: this.trans('home_choose_us_item_1_subtitle'),
          src: require('@/assets/images/card-icons/money_icon.svg')
        },
        {
          order: 2,
          title: this.trans('home_choose_us_item_2_title'),
          description: this.trans('home_choose_us_item_2_subtitle'),
          src: require('@/assets/images/card-icons/check_icon.svg')
        },
        {
          order: 3,
          title: this.trans('home_choose_us_item_3_title'),
          description: this.trans('home_choose_us_item_3_subtitle'),
          src: require('@/assets/images/card-icons/settings_icon.svg')
        },
        {
          order: 4,
          title: this.trans('home_choose_us_item_4_title'),
          description: this.trans('home_choose_us_item_4_subtitle'),
          src: require('@/assets/images/dashboard-icon.svg')
        }
      ]
    }
	}
}
