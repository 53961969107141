<template>
	<v-carousel
		v-if="state.testimonialsList && state.testimonialsList.length"
		hide-delimiters
		:height="$vuetify.breakpoint.smAndDown ? '170' : '320'"
		class="mint carousel"
		prev-icon="$previcon"
		next-icon="$nexticon"
	>
		<v-carousel-item
			v-for="testimonial in state.testimonialsList"
			:key="testimonial.order"
		>
			<v-row
				class="fill-height"
				align="center"
				justify="center"
			>
				<div class="slider-content d-flex flex-column justify-center align-center text-md-subtitle-1 text-center">
					<p
						class="vue-editor font-italic text-caption text-md-subtitle-1"
						v-html="testimonial.message"
					/>
					<p class="mt-md-8 mt-1 text-caption text-md-subtitle-1 font-weight-bold grayText--text">
						{{ testimonial.author }}, {{ testimonial.position }}
					</p>
				</div>
			</v-row>
		</v-carousel-item>
	</v-carousel>
</template>

<script>
import ApiTestimonials from '@/api/testimonial.js'

export default {
	name: 'Slider',
	created () {
		ApiTestimonials.getAll(this)
	}
}
</script>

<style lang="scss" scoped>
.carousel {
	margin-top: 204px;
	margin-bottom: 150px;

	.slider-content {
		padding: 0 260px;
	}
}

.carousel::v-deep() {
	.v-window__prev {
		left: 55px;
	}

	.v-window__next {
		right: 55px;
	}
}

@media #{map-get($display-breakpoints, 'md-only')} {
	.carousel {
		margin-top: 26vw;
	}
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
	.carousel {
		margin-top: 97px;
		margin-bottom: 60px;

		.slider-content {
			padding: 0 68px;
		}
	}

	.carousel::v-deep() {
		.v-window__prev {
			left: 0px !important;
			svg {
				height: 23px !important;
			}
		}

		.v-window__next {
			right: 0px !important;
			svg {
				height: 23px !important;
			}
		}
	}

}
</style>
