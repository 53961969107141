<template>
	<div class="section-wrapper">
		<v-img
			v-if="$vuetify.breakpoint.mdAndUp"
			:width="windowWidth >= 1440 ? '889' : '61%'"
			src="@/assets/images/charts-with-shadow.png"
			class="main-image"
		/>
		<div class="content">
			<v-row
				no-gutters
				justify="space-between"
				class="gateway"
			>
				<v-col
					cols="12"
					md="4"
				>
					<h4
						class="vue-editor text-md-h4 font-weight-bold mb-2 mb-md-9"
						v-html="trans('home_crypto_gateway_section_title')"
					/>
					<ul class="list grayText--text">
						<li
							v-for="(item, itemIndex) in list"
							:key="itemIndex"
							class="vue-editor text-body-2 text-md-subtitle-2"
							v-html="item"
						/>
					</ul>
					<HomeFeaturesFirstBtn class="btn" />
				</v-col>
				<v-col class="ml-auto" cols="12">
					<v-img
						v-if="$vuetify.breakpoint.smAndDown"
						width="100%"
						src="@/assets/images/charts-with-shadow.png"
						class="main-image"
					/>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import HomeFeaturesFirstBtn from '@/components/home/home-buttons/HomeFeaturesFirstBtn'

export default {
	name: 'Gateway',
	components: {
		HomeFeaturesFirstBtn,
	},
	computed: {
		list () {
			return [
				this.trans('home_crypto_gateway_list_item1'),
				this.trans('home_crypto_gateway_list_item2'),
				this.trans('home_crypto_gateway_list_item3'),
				this.trans('home_crypto_gateway_list_item4')
			]
		}
	}
}
</script>

<style lang="scss" scoped>
.section-wrapper {
	min-height: 572px;
}
.content {
	margin-top: 117px;
}

.gateway {
	position: relative;
}

.subtitle {
	line-height: 30px !important;
}

.list {
	padding-left: 0;
	margin-top: 35px;

	li {
		list-style: none;
		position: relative;
		padding-left: 30px;

		&:not(:last-child) {
			margin-bottom: 20px;
		}

		&:before {
			position: absolute;
			content: url('../../../assets/images/mark.svg');
			top: 2px;
			left: 0;

		}
	}
}
.btn {
	margin-top: 45px;
}

.section-wrapper {
	position: relative;
	&::before {
		position: absolute;
		content: "";
		width: 750px;
		height: 750px;
		top: -130px;
		right: -323px;
		border-radius: 50%;
		background: var(--v-mainColor-base);
		opacity: 0.1;
		z-index: 0;
	}
}
.main-image {
	position: absolute !important;
	top: -80px;
	right: 47px;
	z-index: 10;
}
@media #{map-get($display-breakpoints, 'xl-only')} {
	.main-image {
		right: 90px;
	}
}
@media #{map-get($display-breakpoints, 'md-only')} {
	.section-wrapper {
		min-height: initial;
	}
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
	.section-wrapper {
		min-height: initial;
	}
	.content {
		margin-top: 40px;
	}

	.section-wrapper {
		&::before {
			width: 313px;
			height: 313px;
			bottom: -28px;
			top: initial;
			right: -144px;

		}
	}

	.subtitle {
		line-height: 22px !important;
	}

	.list {
		margin-top: 20px;
	}

	.main-image {
		position: relative !important;
		top: 0;
		right: initial;
		transform: scale(1.13) translateX(-7px);
		margin-top: 46px;
	}

}
</style>
