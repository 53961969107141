<template>
<div class="section-wrapper">
	<v-img
		v-if="$vuetify.breakpoint.mdAndUp"
		:width="windowWidth >= 1440 ? '929' : '64%'"
		src="@/assets/images/invoice-with-shadow.png"
		class="main-image"
	/>
	<div class="content">
		<v-row
			no-gutters
			justify="space-between"
			class="flex-md-row flex-column-reverse invoicing"
		>
			<v-col cols="12" md="8">
				<v-img
					v-if="$vuetify.breakpoint.smAndDown"
					width="100%"
					src="@/assets/images/invoice-with-shadow.png"
					class="main-image"
				/>
			</v-col>
			<v-col
				cols="12"
				md="4"
				class="title"
			>
				<h4
					class="vue-editor text-md-h4 text-body-1 font-weight-bold mb-md-9 mb-3"
					v-html="trans('home_email_invoicing_section_title')"
				/>
				<ul class="list grayText--text mt-8">
					<li
						v-for="(item, index) in list"
						:key="index"
						class="vue-editor text-md-subtitle-2 text-body-2"
						v-html="item"
					/>
				</ul>
				<HomeFeaturesSecondBtn class="mt-md-11 mt-8" />
			</v-col>
		</v-row>
	</div>
</div>
</template>

<script>
import HomeFeaturesSecondBtn from '@/components/home/home-buttons/HomeFeaturesSecondBtn'

export default {
	name: 'Invoicing',
	components: {
		HomeFeaturesSecondBtn
	},
	computed: {
		list () {
			return [
				this.trans('home_email_invoicing_list_item1'),
				this.trans('home_email_invoicing_list_item2'),
				this.trans('home_email_invoicing_list_item3')
			]
		}
	}
}
</script>

<style lang="scss" scoped>
.section-wrapper {
	min-height: 540px;
}
.content {
	margin-top: 208px;
}
.invoicing {
	position: relative;
}
.subtitle {
	line-height: 30px !important;
}

.list {
	padding-left: 0;

	li {
		list-style: none;
		position: relative;
		padding-left: 30px;

		&:not(:last-child) {
			margin-bottom: 20px;
		}

		&:before {
			position: absolute;
			content: url('../../../assets/images/mark.svg');
			top: 2px;
			left: 0;
		}
	}
}
.section-wrapper {
	position: relative;
	&::before {
		position: absolute;
		content: "";
		width: 750px;
		height: 750px;
		top: -160px;
		left: -240px;
		border-radius: 50%;
		background: var(--v-mainColor-base);
		opacity: 0.1;
		z-index: 0;
	}
}
.main-image {
	position: absolute !important;
	top: -64px;
	left: 3px;
	z-index: 10;
}
@media #{map-get($display-breakpoints, 'xl-only')} {
	.main-image {
		left: 20px;
	}
}
@media #{map-get($display-breakpoints, 'md-only')} {
	.section-wrapper {
		min-height: initial;
	}
	.content {
		margin-top: 23.6vw;
	}
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
	.section-wrapper {
		min-height: initial;
	}
	.content {
		margin-top: 0;
		margin-bottom: 0;
	}
	.title {
		margin-top: 60px;
	}

	.subtitle {
		line-height: 20px !important;
	}

	.section-wrapper {
		&::before {
			width: 313px;
			height: 313px;
			top: initial;
			bottom: -13px;
			right: -144px;
			left: auto;
		}
	}
	.main-image {
		position: relative !important;
		top: 0;
		margin-top: 46px;
		transform: scale(1.19) translateX(-1px);
	}
}
</style>
