<template>
	<div class="section-wrapper">
		<v-img
			v-if="$vuetify.breakpoint.mdAndUp"
			:width="windowWidth >= 1440 ? '929' : '64%'"
			src="@/assets/images/checkout-with-shadow.png"
			class="main-image"
		/>
		<div class="content">
			<v-row
				no-gutters
				justify="space-between"
				class="integration"
			>
				<v-col
					cols="12"
					md="4"
					class="title"
				>
					<h4
						class="vue-editor text-md-h4 text-body-2 font-weight-bold mb-md-9 mb-2"
						v-html="trans('home_effortless_integration_section_title')"
					/>
					<div class="d-flex flex-row align-end flex-wrap mt-4">
						<div
							class="mr-5 mt-5"
							v-for="item in items"
							:key="item.order"
						>
							<v-img :src="item.imageUrl" />
						</div>
					</div>
					<HomeFeaturesThirdBtn class="btn" />
				</v-col>
				<v-col cols="12" md="8">
					<div>
						<v-img
							v-if="$vuetify.breakpoint.smAndDown"
							width="100%"
							src="@/assets/images/checkout-with-shadow.png"
							class="main-image"
						/>
					</div>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import HomeFeaturesThirdBtn from '@/components/home/home-buttons/HomeFeaturesThirdBtn'

export default {
	name: 'Integration',
	components: {
		HomeFeaturesThirdBtn
	},
	data: () => ({
		items: [
			{
				order: 1,
				imageUrl: require('@/assets/images/woo.svg')
			},
			{
				order: 2,
				imageUrl: require('@/assets/images/shopify.svg')
			},
			{
				order: 3,
				imageUrl: require('@/assets/images/opencart.svg')
			},
			{
				order: 4,
				imageUrl: require('@/assets/images/magento.svg')
			},
			{
				order: 5,
				imageUrl: require('@/assets/images/tor.svg')
			}
		]
	})
}
</script>

<style lang="scss" scoped>
.section-wrapper {
	min-height: 540px;
	margin-top: 202px;
}
.subtitle {
	line-height: 30px !important;
}

.integration {
	position: relative;
}
.list {
	padding-left: 0;

	li {
		list-style: none;
		position: relative;
		padding-left: 30px;

		&:before {
			position: absolute;
			content: url('../../../assets/images/mark.svg');
			top: 2px;
			left: 0;

		}
	}
}

.btn {
	margin-top: 45px;
}
.section-wrapper {
	position: relative;
	&::before {
		position: absolute;
		content: "";
		width: 750px;
		height: 750px;
		top: -152px;
		right: -323px;
		border-radius: 50%;
		background: var(--v-mainColor-base);
		opacity: 0.1;
		z-index: 0;
	}
}
.main-image {
	position: absolute !important;
	top: -86px;
	right: 4px;
	z-index: 10;
}
@media #{map-get($display-breakpoints, 'xl-only')} {
	.section-wrapper {
		&:before {
			right: -282px;
		}
	}
	.main-image {
		right: 49px;
	}
}
@media #{map-get($display-breakpoints, 'md-only')} {
	.section-wrapper {
		min-height: initial;
		margin-top: 0;
		margin-bottom: 0;
	}
	.content {
		margin-top: 21.3vw;
	}
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
	.section-wrapper {
		min-height: initial;
		margin-top: 0;
		margin-bottom: 0;
	}
	.content {
		margin-top: 54px;
	}
	.section-wrapper {
		&::before {
			width: 313px;
			height: 313px;
			top: initial;
			bottom: -27px;
			right: -144px;
		}
	}
	.main-image {
		position: relative !important;
		top: 12px;
		margin-top: 46px;
		right: initial;
		transform: scale(1.19) translateX(-1px);
	}

	.subtitle {
		line-height: 22px !important;
	}
}
</style>
