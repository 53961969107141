<template>
  <div class="content">
    <v-row no-gutters>
      <v-col cols="12">
        <p
					class="vue-editor mb-10 mb-md-15 font-weight-bold black--text text-body-2 text-md-subtitle-2 title"
					v-html="trans('home_section_sponsors_title')"
				/>
				<div class="d-flex justify-space-between align-center flex-wrap image-container">
					<div
						v-for="sponsor in state.homeSponsorList"
            :key="sponsor.id"
					>
						<a :href="sponsor.link" target="_blank">
							<v-img
								position="left center"
								contain
								:width="$vuetify.breakpoint.smAndDown ? '150' : '300'"
								:height="$vuetify.breakpoint.smAndDown ? '27' : '40'"
								:src="sponsor.imageUrl"
								alt=""
							/>
						</a>
					</div>
				</div>
      </v-col>
    </v-row>
  </div>

</template>

<script>
import HomeSponsor from '@/api/home-sponsor.js'

export default {
  name: 'AsSeenOn',
	created () {
		HomeSponsor.getAll(this)
	}
}
</script>

<style lang="scss" scoped>
.content {
	margin-top: 150px;
  margin-bottom: 150px;
}
.image-container {
	row-gap: 40px;
	column-gap: 10px;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .content {
		margin-top: 0;
    margin-bottom: 40px;

  }

  .subtitle {
    padding-bottom: 10px;
  }
  .image-container {
    row-gap: 37px;
  }
}
</style>
