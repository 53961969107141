import ApiBase from '@/api/base'

class HomeSponsor {
	static async getAll (self) {
		try {
			let response = await self.http('get', `${ApiBase.baseApiUrl()}/home-sponsors`)
			ApiBase.setToStore(null, 'setHomeSponsorList', response.data)
		} catch (error) {
			console.error('error', error)
		}
  }
}

export default HomeSponsor
