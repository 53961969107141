<template>
  <div class="content">
    <v-row no-gutters class="flex-column mb-10 mb-md-15">
      <v-col cols="12" align-self="center">
        <p
					class="vue-editor text-md-h2 text-body-1 text-center font-weight-black"
					v-html="trans('home_join_section_title')"
				/>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="d-flex justify-center">
        <HomeLastBtn />
      </v-col>

    </v-row>
  </div>
</template>

<script>
import HomeLastBtn from '@/components/home/home-buttons/HomeLastBtn'

export default {
  name: 'Join',
  components: {
    HomeLastBtn
  }
}
</script>

<style lang="scss" scoped>
.content {
  margin-bottom: 150px;
}

.consult-btn {
  position: relative;

  span:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    background-color: black;
    bottom: -5px;
    left: 0;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .content {
    margin-bottom: 60px;
  }
}
</style>
