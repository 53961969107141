<template>

	<div
		v-if="state.providersCategoriesList
			&& state.providersCategoriesList.length
			&& state.providersList
			&& state.providersList.length"
		class="content"
	>
		<v-row no-gutters class="flex-column">
			<v-col cols="10" md="7">
				<h1
					class="vue-editor text-md-h2 text-subtitle-1 font-weight-black title"
					v-html="trans('home_comparing_section_title')"
				/>
			</v-col>
		</v-row>
		<v-row no-gutters>
			<div class="d-flex flex-column justify-end card pb-md-13 titles mt-5 mt-md-0 pl-0 ">
				<div class="first-card ">
					<p
						v-for="item in state.providersCategoriesList"
						:key="item.order"
						class="vue-editor text-caption text-md-subtitle-2 font-weight-bold"
						v-html="trans(item.name)"
					/>
				</div>
			</div>
			<div
				v-for="card in state.providersList.slice(0, 1)"
				:key="card.order"
				class="d-flex flex-column justify-md-end align-center card card-bordered pb-md-13 pt-6"
			>
				<div class="card-content">
					<v-img
						:max-width="$vuetify.breakpoint.smAndDown ? '67' : '89'"
						:src="card.imageUrl"
					/>
					<v-divider class="mt-6 mb-2" />
					<p
						v-for="info in card.info"
						:key="info"
						class="vue-editor text-caption text-md-subtitle-2 font-weight-bold mt-md-7"
						v-html="info"
					/>
				</div>
			</div>
			<div
				v-for="card in state.providersList.slice(1)"
				:key="card.order"
				class="d-flex flex-column justify-end align-center card pb-md-13 pl-0 pt-6"
			>
				<div class="card-content">
					<div>
						<v-img
							contain
							:max-height="$vuetify.breakpoint.smAndDown ? '17' : '20'"
							position="left center"
							:src="card.imageUrl"
						/>
					</div>
					<v-divider class="mt-6 mb-2"></v-divider>
					<p
						v-for="info in card.info"
						:key="info"
						class="vue-editor text-caption text-md-subtitle-2 font-weight-bold mt-md-7"
						v-html="info"
					/>
				</div>
			</div>
		</v-row>
	</div>

</template>

<script>
import ApiProviders from '@/api/home-comparing-providers.js'
import ApiProvidersCategories from '@/api/home-comparing-providers-categories.js'

export default {
	name: 'Comparing',
	data: () => ({
		categories: [
			{
				order: 1,
				name: 'transaction_costs'
			},
			{
				order: 2,
				name: 'supported_coins'
			}
		],
		providers: [
			{
				order: 1,
				imageUrl: require('@/assets/images/card-icons/netpay_logo.svg'),
				info: {
					transaction_costs: '0.3%',
					supported_coins: 20
				}
			},
			{
				order: 2,
				imageUrl: require('@/assets/images/card-icons/bitpay_logo.svg'),
				info: {
					transaction_costs: '1%',
					supported_coins: 5
				}
			},
			{
				order: 3,
				imageUrl: require('@/assets/images/card-icons/coingate_logo.svg'),
				info: {
					transaction_costs: '1%',
					supported_coins: 54
				}
			},
			{
				order: 4,
				imageUrl: require('@/assets/images/card-icons/coinsbank_logo.svg'),
				info: {
					transaction_costs: '0.5%',
					supported_coins: 4
				}
			}
		]
	}),
	created() {
		ApiProvidersCategories.getAll(this)
		ApiProviders.getAll(this)
	}
}
</script>

<style lang="scss" scoped>
.content {
	margin-top: 150px;
}

.title {
	margin-bottom: 100px;
}

.titles {
	margin-right: 115px;
}

.card-content {
	width: 116px;
}

.card {
	border: 1px solid white;
	width: 215px;
	min-height: 240px;

	p:not(:first-child) {
		margin-top: 20px;
	}
}

.card-bordered {
	border: 1px solid var(--v-mainColor-base);
	border-radius: 15px;
}

.first-card {
	// padding-top: 114px;

	p:last-child {
		margin-top: 30px;
	}
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {

	.content {
		margin-top: 0;
	}

	.titles {
		margin-right: 40px;
	}

	.title {
		margin-bottom: 30px;
	}

	.card {
		width: auto;
		min-height: 146px;
		margin-bottom: 0;
		padding: 15px 20px;
	}

	.card-content {
		width: 79px;

	}

	.first-card {

		p:not(:first-child) {
			margin-top: 20px;
		}
	}

}
</style>
