<template>
  <div class="bestway">
    <div class="content">
      <v-row no-gutters class="flex-column">
        <v-col cols="12" md="6">
          <p
						class="vue-editor text-md-h2 text-subtitle-1 font-weight-black"
						v-html="trans('home_best_way_section_title')"
					/>
        </v-col>
        <v-col cols="12" md="9">
          <p
						class="vue-editor subtitle text-md-subtitle-1 text-body-2 grayText--text"
						v-html="trans('home_best_way_section_subtitle')"
					/>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        justify="space-between"
        class="mt-11 mt-md-15"
      >
        <v-col
          class="mb-10 mb-md-0"
          cols="6" md="auto"
          v-for="item in items"
          :key="item.order"
        >
          <div class="font-weight-bold numbers text-uppercase">
            <span
							class="vue-editor big-title"
							v-html="item.title"
						/>
          </div>
          <p
						class="vue-editor text-md-subtitle-1 text-body-2 grayText--text"
						v-html="item.sub_title"
					/>
        </v-col>
      </v-row>
    </div>

  </div>
</template>

<script>
export default {
  name: 'BestWay',
  computed: {
    items () {
      return [
        {
          order: '1',
          title: '1m',
          sub_title: this.trans('home_best_way_content_1')
        },
        {
          order: '2',
          title: '4,753',
          sub_title: this.trans('home_best_way_content_2')
        },
        {
          order: '3',
          title: '100K+',
          sub_title: this.trans('home_best_way_content_3')
        },
        {
          order: '4',
          title: '100K+',
          sub_title: this.trans('home_best_way_content_4')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.bestway {
  background: var(--v-mint-base);

  .content {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .subtitle {
    padding-top: 35px;
  }

  .numbers {
    margin-bottom: 15px;
  }

}

@media #{map-get($display-breakpoints, 'sm-and-down')} {

.bestway {

  .content {
    padding-top: 40px;
    padding-bottom: 0;
  }

  .subtitle {
    padding-top: 10px;
  }

}
}
</style>
