<template>

  <div>
    <div class="content">
      <v-row no-gutters class="flex-column">
        <v-col cols="12">
          <h2
						class="vue-editor text-center text-md-h2 text-subtitle-1 font-weight-black title"
						v-html="trans('home_features_section_title')"
					/>
        </v-col>
      </v-row>
    </div>
    <Gateway />
    <Invoicing />
    <Integration v-if="state.globalSettings.show_effortless_integration_section_on_home_page" />
  </div>

</template>

<script>
import Gateway from './features/Gateway'
import Invoicing from './features/Invoicing'
import Integration from './features/Integration'

export default {
  name: 'Features',
  components: {
    Gateway,
    Invoicing,
    Integration
  },
  data: () => ({

  })
}
</script>

<style lang="scss" scoped>
.content {
  .title {
    margin-top: 150px;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .content {
    .title {
      margin-top: 30px;
    }
  }
}
</style>
