<template>
  <div class="content">
    <v-row no-gutters class="flex-column ">
      <v-col cols="12" md="7">
        <h1
					class="vue-editor text-md-h2 text-subtitle-1 font-weight-black title mb-3 mb-md-8"
					v-html="trans('home_supported_coins_section_title')"
				/>
      </v-col>
      <v-col
        cols="12"
        md="8"
        class="subtitle">
        <p
					class="vue-editor text-md-subtitle-1 text-body-2 grayText--text"
					v-html="trans('home_supported_coins_section_subtitle')"
				/>
      </v-col>
    </v-row>
    <div class="d-flex coins flex-wrap justify-space-between">
      <div
        v-for="coin in coins"
        :key="coin.order"
        class="coin mt-10 pb-md-5 d-flex flex-column justify-center align-center mb-md-3">
        <div class="mb-6">
          <v-img
            contain
            :height="$vuetify.breakpoint.smAndDown ? '35' : '45'"
            :width="$vuetify.breakpoint.smAndDown ? '35' : '45'"
            :src="coin.imageUrl"></v-img>
        </div>
        <p
					class="vue-editor text-md-body-1 text-center text-overline mb-2"
					v-html="coin.name"
				/>
        <p
					class="vue-editor text-md-body-1 text-center text-overline"
					v-html="coin.short_name"
				/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SupportedCoins',
  data: () => ({
    coins: [
      {
        order: 1,
        name: 'Bitcoin',
        short_name: 'BTC',
        imageUrl: require('@/assets/images/bitcoin.svg')
      },
      {
        order: 2,
        name: 'Ethereum',
        short_name: 'ETH',
        imageUrl: require('@/assets/images/ethereum.svg')
      },
      {
        order: 3,
        name: 'Cardano',
        short_name: 'ada',
        imageUrl: require('@/assets/images/cardano.svg')
      },
      {
        order: 4,
        name: 'XRP',
        short_name: 'XRP',
        imageUrl: require('@/assets/images/xrp.svg')
      },
      {
        order: 5,
        name: 'Litecoin',
        short_name: 'LTC',
        imageUrl: require('@/assets/images/litecoin.svg')
      },
      {
        order: 6,
        name: 'Bitcoin Cash',
        short_name: 'BCH',
        imageUrl: require('@/assets/images/bitcoin-cash.svg')
      },
      {
        order: 7,
        name: 'Tether',
        short_name: 'USDT',
        imageUrl: require('@/assets/images/tether.svg')
      },
      {
        order: 8,
        name: 'Binance Coin',
        short_name: 'BNB',
        imageUrl: require('@/assets/images/bnb.svg')
      },
      {
        order: 9,
        name: 'TRON',
        short_name: 'TRX',
        imageUrl: require('@/assets/images/tron.svg')
      },
      {
        order: 10,
        name: 'DAI ETH',
        short_name: 'DAI',
        imageUrl: require('@/assets/images/dai.svg')
      },
      {
        order: 11,
        name: 'Bitcoin SV',
        short_name: 'BSV',
        imageUrl: require('@/assets/images/bsv.svg')
      },
      {
        order: 12,
        name: 'Binance USD',
        short_name: 'BUSD',
        imageUrl: require('@/assets/images/busd.svg')
      },
      {
        order: 13,
        name: 'Polygon',
        short_name: 'MATIC',
        imageUrl: require('@/assets/images/polygon.svg')
      },
      {
        order: 14,
        name: 'TETHER',
        short_name: 'EURTE',
        imageUrl: require('@/assets/images/eurte.svg')
      },
      {
        order: 15,
        name: 'USD Coin',
        short_name: 'USDC',
        imageUrl: require('@/assets/images/usd-coin.svg')
      },
      {
        order: 16,
        name: 'Dogecoin',
        short_name: 'DOGE',
        imageUrl: require('@/assets/images/dogecoin.svg')
      },
    ]
  })
}
</script>

<style lang="scss" scoped>
.content {
  margin-top: 150px;
}

.subtitle {
  margin-bottom: 60px;
}

.coins {
  .coin {
    width: calc(100% / 8);
    &:not(:last-child) {
      // margin-right: 25px;
    }
  }

}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .content {
    margin-top: 0;
  }

  .subtitle {
    margin-bottom: 5px;
  }


  .coins {
    // margin-top: 0;

    .coin {
      width: calc(100% / 4);

      &:not(:nth-child(4n)) {
        // margin-right: 25px;
      }
    }
  }

}
</style>
