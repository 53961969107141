import ApiBase from '@/api/base'

class ApiProvidersCategories {
  static getAll (self) {
    self.http('get', `${ApiBase.baseApiUrl()}/home-comparing-providers-categories`)
      .then(response => {
        ApiBase.setToStore(null, 'setProvidersCategoriesList', response.data)
      })
  }
}

export default ApiProvidersCategories
