import ApiBase from '@/api/base'

class ApiTestimonials {
  static getAll (self) {
    self.http('get', `${ApiBase.baseApiUrl()}/testimonials`)
      .then(response => {
        ApiBase.setToStore(null, 'setTestimonialsList', response.data)
      })
  }
}

export default ApiTestimonials
